import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import { PaymentMethodCode } from './models/Account/IAccount';

declare global {
  interface Window {
    pwconfig: {
      contact_email: string,
      languages: string[],
      teams_tariff_id: number,
      default_payment_method: PaymentMethodCode,
      payment_methods: string[],
      firebase_config: any,
      help_center_domain: string,
      tag_manager_code: string,
      urls: {
        license_agreement: string,
        landing_page: string,
      },
      panel: {
        batch_size: number,
        prolific: {
          batch_size: number,
          minimum_base_test_duration_mins: number,
        },
        crowd: {
          batch_size: number,
          minimum_base_test_duration_mins: number,
        }
      }
    };
  }
}

// Initialize Firebase
firebase.initializeApp(window.pwconfig.firebase_config);

export let auth = firebase.auth();
export let db = firebase.firestore();
export let storage = firebase.storage();
export let FieldValue = firebase.firestore.FieldValue;
export let Timestamp = firebase.firestore.Timestamp;

export const getAuthToken = () =>
  new Promise((resolve) => {
    //@ts-ignore
    auth.currentUser.getIdToken(true).then(resolve);
  });

export const signInWithGoogle = () => {
  const provider = new firebase.auth.GoogleAuthProvider();
  return auth.signInWithPopup(provider);
};

export const signInWithFacebook = () => {
  const provider = new firebase.auth.FacebookAuthProvider();
  return auth.signInWithPopup(provider);
};

export const signInWithEmail = (email: string, redirectUrl: string) => {
  let url = `${window.location.protocol}//${window.location.host}/login/with-email`;

  if (redirectUrl) {
    url = url + `?redirectUrl=${redirectUrl}`;
  }

  return auth.sendSignInLinkToEmail(email, {
    url,
    handleCodeInApp: true,
  });
};
