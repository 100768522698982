import React, { useState, useEffect } from "react";
import "../../tailwind.generated.css";
import { formatDate } from "../../i18n/utils";


import RenderIfVisible from "react-render-if-visible";

import FigmaReport from "./FigmaReport/FigmaReport";
import ClicksTab from "./FirstClickReport/ClicksTab";
import CardSortReportMatrixTable from "./CardSortReport/CardSortReportMatrixTable";
import MatrixReportTable from "./MatrixReport/MatrixReportTable";
import UrlTag from "./UrlTag";
import SearchInput from "./ResponsesSearchInput";
import ResponsesList from "./ResponsesList";

import { getScaleRepliesList, findReplyNameById, searchResponses } from "./utils";
import { getUserDefinedCategoriesForIndividualResponse } from "./CardSortReport/utils";

import Button from "../Button";
import Modal from "../Modal";
import { IconDelete } from "../../icons";
import { Block } from "../../models/Test";
import { BlockType, FirstClickBlock, PreferenceBlock, ScaleBlock, FigmaBlock, CardSortBlock, isMatrixBlock } from "../../models/Test";
import { blockTypeMap } from "../Common";
import { IReportTestData, IUrlTags } from "../../models/Report";
import {
  IBlockTestResponse,
  IIndividualTestResponse,
  IOpenQuestionResponse,
  IChoiceResponse,
  IFigmaResponse,
  IMatrixResponse,
  IPreferenceResponse,
  IScaleResponse,
  isChoiceResponse,
  isFigmaResponse,
  isOpenQuestionsResponse,
  isMatrixResponse,
  isPreferenceResponse,
  isScaleResponse,
  isFirstClickResponse,
  isCardSortResponse,
  isAIResponse,
} from "../../models/Response";
import { useTranslation } from "react-i18next";
import { AiChatReport } from './AiChatReport/AiChatReport';
import { TestBlockReportContext } from './ReportContext';

const defaultBlockIconSize = {
  width: "24px",
  height: "24px",
};
const bigBlockIconSize = {
  width: "24px",
  height: "24px",
};
const DeleteIcon = <IconDelete className="fill-current text-gray-700 hover:text-red-600" width={24} height={24} />;

type ResponsesProps = {
  testId: string;
  testData: IReportTestData;
  response: IIndividualTestResponse | null | undefined;
  handleDeleteResponse: (answerId: string) => Promise<void>;
  sharedByLink: boolean;
  sharingToken?: string;
};

export default function Responses(props: ResponsesProps) {
  const { t } = useTranslation();
  const { testData } = props;


  if (!props.response) {
    return null;
  }

  return (
    <div className="responses__wrapper h-full w-full grow-default flex flex-col gap-6">
      <Response
        testId={props.testId}
        testData={testData}
        response={props.response}
        handleDeleteResponse={props.handleDeleteResponse}
        sharedByLink={props.sharedByLink}
        sharingToken={props.sharingToken}
      />
      <div className="flex items-center gap-2 text-gray-600 text-base">
        {t("Use")}
        <span className="w-4 h-4 flex items-center justify-center border-gray-400 border-1 rounded-[4px]">↑</span>
        <span className="w-4 h-4 flex items-center justify-center border-gray-400 border-1 rounded-[4px]">↓</span>
        {t("to switch between responses")}
      </div>
    </div>
  );
}

type ResponseProps = {
  testId: string;
  testData: IReportTestData;
  response: IIndividualTestResponse | undefined;
  handleDeleteResponse: (answerId: string) => Promise<void>;
  sharedByLink: boolean;
  sharingToken?: string;
};

function Response(props: ResponseProps) {

  const { i18n, t } = useTranslation();
  const response = props.response;
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  if (!response) return null;

  const blockData = response.blockAnswers[0].data;
  const hasResponseDate = !!(blockData.submittedAt || blockData.completedAt);
  const responseDate = new Date(blockData.submittedAt || blockData.completedAt);
  const dateStr = hasResponseDate ? formatDate(responseDate, i18n.language) : '';
  const urlTags = response.urlTags;

  async function deleteResponse() {
    try {
      setIsDeleting(true);
      await props.handleDeleteResponse((response as IIndividualTestResponse).answerId);
      setIsDeleting(false);
      setIsDeleteModalOpen(false);
    } catch (error) {
      setIsDeleting(false);
      setIsDeleteModalOpen(false);
      console.error(error);
    }
  }

  function closeModal() {
    setIsDeleteModalOpen(false);
  }

  return (
    <div className="response__el group rounded-xl bg-white p-5 shadow-xs w-full">
      <div className="response__header flex justify-between items-center mb-4">
        <div className="text-gray-700 text-base">{dateStr}</div>
        {!props.sharedByLink && (
          <>
            <div
              className="response__delete cursor-pointer invisible group-hover:visible"
              onClick={() => setIsDeleteModalOpen(true)}
            >
              {DeleteIcon}
            </div>
            {isDeleteModalOpen && (
              <Modal isOpen={isDeleteModalOpen} setIsOpen={setIsDeleteModalOpen} width="40%" background>
                <div className="p-6">
                  <div className="flex text-3xl mb-3 font-medium">Delete response</div>
                  <div>Are you sure? This is irreversible.</div>
                  <div className="flex mt-4 justify-between">
                    <Button
                      name="Cancel"
                      type="secondary"
                      handler={closeModal} />
                    <Button
                      name="Yes, delete this response"
                      type="danger"
                      isLoading={isDeleting}
                      handler={deleteResponse} />
                  </div>
                </div>
              </Modal>
            )}
          </>
        )}
      </div>
      {urlTags && Object.keys(urlTags).length !== 0 && (
        <div className="response__tags mb-4 flex items-center justify-start gap-2 flex-wrap">
          {Object.keys(urlTags).map((key, index) => (
            <UrlTag tag={key} value={(urlTags as IUrlTags)[key]} />
          ))}
        </div>
      )}
      {response.blockAnswers.map((blockResponse, i) => {
        const testBlockContext = {
          testId: props.testId,
          block: props.testData.publishedContent.find(b => b.blockId === blockResponse.blockId) as Block,
          sharedByLink: props.sharedByLink,
          sharingToken: props.sharingToken,
        };
        return (
          <div className='response__block-answer' key={`${response.answerId}-${i}`}>
            <TestBlockReportContext.Provider value={testBlockContext}>
              {i !== 0 && <div className="w-full h-px bg-gray-300 my-5"></div>}
              <BlockResponse
                testId={props.testId}
                answerId={response.answerId}
                userAgent={response.userAgent}
                blockId={blockResponse.blockId}
                blockType={blockResponse.blockType}
                testData={props.testData}
                blockResponse={blockResponse.data}
                date={dateStr} />
            </TestBlockReportContext.Provider>
          </div>
        );
      })}
    </div>
  );
}

type BlockResponseProps = {
  testId: string;
  answerId: string;
  userAgent: string | undefined;
  blockId: string;
  blockType: BlockType;
  testData: IReportTestData;
  blockResponse:
  | IOpenQuestionResponse
  | IChoiceResponse
  | IFigmaResponse
  | IMatrixResponse
  | IPreferenceResponse
  | IScaleResponse
  | IBlockTestResponse;
  date: string;
};

function BlockResponse({ answerId, userAgent, blockId, blockType, testData, blockResponse, date, testId }: BlockResponseProps) {
  const blockData = testData.publishedContent.find((e) => e.blockId === blockId);

  if (!blockData) return null;

  if (blockType === BlockType.openquestion && isOpenQuestionsResponse(blockResponse)) {
    return (
      <>
        <BlockResponseHeader blockType={blockType} blockData={blockData} />
        <div className="mt-4">{blockResponse.textValue}</div>
      </>
    );
  }
  if (blockType === BlockType.choice && isChoiceResponse(blockResponse)) {
    return (
      <>
        <BlockResponseHeader blockType={blockType} blockData={blockData} />
        <div className="mt-4">
          {blockResponse.selectedOptions.map((option) => (
            <span key={option.id} className="px-2 rounded-full inline-block border-gray-400 border-2 mr-2 mb-2">
              {option.value}
            </span>
          ))}
        </div>
      </>
    );
  }
  if (blockType === BlockType.scale && isScaleResponse(blockResponse)) {
    const repliesList = getScaleRepliesList(blockData as ScaleBlock);
    // Scale block results
    return (
      <>
        <BlockResponseHeader blockType={blockType} blockData={blockData} />
        <div className="mt-4">
          <span className="px-2 py-1 rounded-full inline-block border-gray-400 border-2 mr-2 mb-2">
            {findReplyNameById(blockResponse.selectedOption, repliesList)}
          </span>
        </div>
      </>
    );
  }
  if (blockType === BlockType.preference && isPreferenceResponse(blockResponse)) {
    const selectedOptionsMap = blockResponse.selectedOptions
      .map((selectedOption) => ({
        option: selectedOption,
        reply: (blockData as PreferenceBlock).replies.find((r) => r.id === selectedOption.id),
      }))
      .filter((o) => !!o.reply);

    // Preference Block Results
    return (
      <>
        <BlockResponseHeader blockType={blockType} blockData={blockData} />
        <div className="text-xl mt-4">
          {selectedOptionsMap.map((optionMap) => {
            return (
              <div className="flex items-center justify-start" key={optionMap.option.id}>
                <img className="rounded-lg w-16 h-16 object-contain bg-gray-200" src={optionMap?.reply?.image} alt="" />
                <span className="ml-4">{optionMap?.reply?.replyValue}</span>
              </div>
            );
          })}
        </div>
      </>
    );
  }
  if (blockType === BlockType.firstclick && isFirstClickResponse(blockResponse)) {
    return (
      <>
        <BlockResponseHeader blockType={blockType} blockData={blockData} />
        <div className="text-xl mt-4">
          <div className="my-4">Response time: {Math.floor(blockResponse.clickData.responseTime / 1000)}s</div>
          <ClicksTab
            imageUrl={(blockData as FirstClickBlock).image}
            responses={[blockResponse]}
            summaryReport={false}
          />
        </div>
      </>
    );
  }

  if (blockType === BlockType.figma && isFigmaResponse(blockResponse)) {
    // Figma Block Results
    return (
      <>
        <BlockResponseHeader blockType={blockType} blockData={blockData} />
        <div className="report-block__body text-xl mt-4">
          <FigmaReport
            // @ts-ignore
            block={blockData}
            // here is an array of responses: here we can see only one response, because it's not a summary report
            responses={[{ ...blockResponse, answerId: answerId, userAgent: userAgent, date: date }]}
            isSummaryReport={false}
            sharingToken={testData.sharingToken}
          />
        </div>
      </>
    );
  }

  if (blockType === BlockType.cardsort && isCardSortResponse(blockResponse)) {

    const reportCards = [...(blockData as CardSortBlock).cards];
    const predefinedReportCategories = [...(blockData as CardSortBlock).categories];
    const reportCategories = [...predefinedReportCategories, ...getUserDefinedCategoriesForIndividualResponse(blockResponse, (blockData as CardSortBlock).categories)];

    const cardsData: { [cardId: string]: { [categoryId: string]: number } } = {};

    for (const category of blockResponse.sorting) {
      for (const card of category.cards) {
        if (!cardsData[card.id]) {
          cardsData[card.id] = {};
        }

        const categoryKey = `${category.id}:${category.value}`;
        
        if (!cardsData[card.id][categoryKey]) {
          cardsData[card.id][categoryKey] = 0;
        }
        cardsData[card.id][categoryKey]++;
      }
    }

    // Card sort block results
    return (
      <>
        <BlockResponseHeader blockType={blockType} blockData={blockData} />
        <CardSortReportMatrixTable
          blockId={blockData.blockId}
          individual={true}
          response={blockResponse}
          columns={reportCategories}
          rows={reportCards}
          cardsData={cardsData}
        />
      </>
    );
  }

  if (blockType === BlockType.matrix && isMatrixResponse(blockResponse) && isMatrixBlock(blockData)) {
    const rows = [...blockData.rows];
    const columns = [...blockData.columns];

    return (
      <>
        <BlockResponseHeader blockType={blockType} blockData={blockData} />
        <MatrixReportTable
          blockId={blockData.blockId}
          individual={true}
          response={blockResponse.selectedOptions}
          columns={columns}
          rows={rows}
        />
      </>
    );
  }

  if (blockType === BlockType.ai && isAIResponse(blockResponse)) {
    return (<>
      <BlockResponseHeader blockType={blockType} blockData={blockData} />
      <AiChatReport chatId={blockResponse.chatId} testId={testId} block={blockData as any} />
    </>);
  }

  return <></>;
}


type BlockResponseHeaderProps = {
  blockType: BlockType;
  blockData: Block | undefined;
};

function BlockResponseHeader({ blockType, blockData }: BlockResponseHeaderProps) {
  if (!blockData) return null;

  let blockTitle = undefined;

  if ("text" in blockData) {
    blockTitle = blockData.text;
  }
  if ("instruction" in blockData) {
    blockTitle = blockData.instruction;
  }

  return (
    <div className="flex items-start justify-center">
      {blockTypeMap[blockType].getIcon(defaultBlockIconSize)}
      <div className="ml-3 flex-1 font-medium text-gray-800">{blockTitle}</div>
    </div>
  );
}

