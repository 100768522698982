import React, { useEffect, useState } from 'react';
import { FormikHelpers, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import clsx from 'clsx';

import { SignInButton } from './SignInButton';
import { useLoginByCode } from './useLoginByCode';
import { CollapsibleContainer } from '../../../Common/Containers/CollapsibleContainer';
import Button from '../../../Button';

export interface SignWithEmailDTO {
  email: string;
}
const SignWithEmailDTOSchema = yup.object().shape({
  email: yup.string().email('Email is incorrect').required().default(''),
});

export default function LoginWithEmail() {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  // state for isCodeSent
  const [isCodeSent, setIsCodeSent] = useState(false);
  const [verificationError, setVerificationError] = useState<string | null>(null);

  const authByCode = useLoginByCode({
    onSendCodeError: onLoginError
  });

  useEffect(() => {
    if (verificationError) {
      setTimeout(() => {
        setVerificationError(null);
      }, 3000);
    }
  }, [verificationError])

  async function onEmailSubmit(values: SignWithEmailDTO, helpers: FormikHelpers<SignWithEmailDTO>) {
    setEmail(values.email);
    try {
      helpers.setSubmitting(true);
      await authByCode.sendCode.mutateAsync(values);
      setIsCodeSent(true);
    } catch (error) {
      console.log(error);
    }
    finally {
      helpers.setSubmitting(false);
    }
  }

  function onResetForm() {
    setIsCodeSent(false);
  }

  function onLoginError(reason: string) {
    if (reason === 'invalid_auth_code') setVerificationError(t('Entered code is invalid, please check and try again'));
    else if (reason === 'too_many_requests') setVerificationError(t('The number of attempts is exceeded, please try again later'));
    else setVerificationError(t("Oops, something went wrong."));
  }

  return <div className='email-form '>
    {!isCodeSent && <EmailForm email={email} verificationError={verificationError} onSubmit={onEmailSubmit} />}

    {!!email && isCodeSent && <CodeForm email={email} onReset={onResetForm} />}
  </div>
}

function EmailForm(props: { email: string, verificationError: string | null, onSubmit: (values: SignWithEmailDTO, helpers: FormikHelpers<SignWithEmailDTO>) => Promise<void> }) {
  const { t } = useTranslation();

  return <Formik
    initialValues={{ email: props.email }}
    validationSchema={SignWithEmailDTOSchema}
    onSubmit={props.onSubmit}
  >
    {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
      <form onSubmit={handleSubmit} className='flex flex-col items-center'>
        <input
          name="email"
          inputMode='email'
          disabled={isSubmitting}
          className="default-input"
          style={{ width: 320 }}
          placeholder={t("Enter your email here")}
          value={values.email}
          onChange={handleChange}
          onBlur={handleBlur} />
        <div className="max-w-xs mx-auto mb-6 text-left mt-4 font-medium text-gray-700">{t("We'll send a confirmation code to this email address.")}</div>
        <CollapsibleContainer className='mb-2 mt-2' isCollapsed={!props.verificationError} minH={'h-0'} maxH={'h-8'}>
          <div className={clsx("text-base font-medium text-red-600 transition-opacity duration-200 ease-in-out", {
            "opacity-0": !props.verificationError,
            "opacity-100": !!props.verificationError,
          })}>
            {props.verificationError || ''}&nbsp;
          </div>
        </CollapsibleContainer>

        <SignInButton disabled={(errors.email && touched.email) || isSubmitting}>
          {isSubmitting ? t("Please, wait...") : t("Send code")}
        </SignInButton>
      </form>
    )}
  </Formik>;
}

const codeSchema = yup.object().shape({
  code: yup.string().length(6, 'Code is incorrect').matches(/^\d{6}$/, 'Code is incorrect').required().default(''),
});

function CodeForm(props: { email: string, onReset: () => void }) {
  const { t } = useTranslation();
  const [verificationError, setVerificationError] = useState<string | null>(null);

  const authByCode = useLoginByCode({ onVerifyCodeError: onLoginError });
  const { countdown, setCountdown } = useCountdown(60);
  const countdownStr = countdown.toString().padStart(2, '0');

  useEffect(() => {
    if (verificationError) {
      setTimeout(() => {
        setVerificationError(null);
      }, 3000);
    }
  }, [verificationError])

  async function onCodeSubmit(values: { code: string }, helpers: FormikHelpers<{ code: string }>) {
    helpers.setSubmitting(true);
    await authByCode.verifyCode.mutateAsync({ email: props.email, code: values.code });
    helpers.setSubmitting(false);
  }

  async function resendCode() {
    try {
      await authByCode.sendCode.mutateAsync({ email: props.email });
      setCountdown(60);
    } catch (error) {
      console.error(error);
    }
  }

  function onLoginError(reason: string) {
    // if (reason === 'invalid_auth_code') setNotification('error', t('Entered code is invalid, please check and try again'));
    // if (reason === 'too_many_requests') setNotification('error', t('The number of attempts is exceeded, please try again later'));
    if (reason === 'invalid_auth_code') setVerificationError(t('Entered code is invalid, please check and try again'));
    else if (reason === 'too_many_requests') setVerificationError(t('The number of attempts is exceeded, please try again later'));
    else setVerificationError(t("Oops, something went wrong."));
  }

  return <Formik
    initialValues={{ code: '' }}
    validationSchema={codeSchema}
    onSubmit={onCodeSubmit}
  >
    {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, ...formik }) => (
      <form onSubmit={handleSubmit} className='flex flex-col items-center'>
        <div className="max-w-xs mx-auto mb-2">
          {t("We've sent a confirmation code to {{email}}, please enter the code below.", { email: props.email })}
        </div>
        <input
          name="code"
          type='tel'
          inputMode='numeric'
          maxLength={6}
          minLength={6}
          pattern='[0-9]{6}'
          disabled={isSubmitting}
          className="text-2xl leading-tight px-1 py-2 text-center input-appearence-none"
          style={{ width: 320 }}
          placeholder={"· · · · ·"}
          value={values.code}
          onChange={(e) => /^\d{0,6}$/.test(e.target.value) && handleChange(e)}
          onBlur={handleBlur} />

        <CollapsibleContainer className='mb-4' isCollapsed={!verificationError} minH={'h-0'} maxH={'h-6'}>
          <div className={clsx("text-base font-medium text-red-600 transition-opacity duration-200 ease-in-out", {
            "opacity-0": !verificationError,
            "opacity-100": !!verificationError,
          })}>
            {verificationError || ''}&nbsp;
          </div>
        </CollapsibleContainer>

        <div className='text-gray-700 text-xs mb-2 text-center'>
          {countdown > 0 && <a className='cursor-not-allowed'>{t("Resend code in 0:{{seconds}}", { seconds: countdownStr })}</a>}
          {countdown === 0 && <a type='text' onClick={() => { resendCode(); formik.resetForm(); }}>
            {authByCode.sendCode.isPending ? t("Sending a new code...") : t("Resend code")}
          </a>}
        </div>
        <div className='text-gray-700 text-xs mb-4 text-center'>
          <a className='' type='text' onClick={props.onReset}>{t("Change email")}</a>
          {/* {" "}{t("or")}{" "} */}
        </div>

        <SignInButton disabled={!values.code || !!errors.code || isSubmitting}>
          {isSubmitting ? t("Please, wait...") : t("Sign in")}
        </SignInButton>


      </form>
    )}
  </Formik>;
}

function useCountdown(seconds: number) {
  const [countdown, setCountdown] = useState(seconds);

  useEffect(() => {
    if (countdown === 0) return;

    const interval = setInterval(() => {
      setCountdown(countdown - 1);
    }, 1000);

    return () => clearInterval(interval);
  }, [countdown]);

  return { countdown, setCountdown };
}