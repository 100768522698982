import { useMemo } from 'react';
import { IReportStateByTester } from '../../../FigmaReport';
import { FigmaPathExplorationProps, NodeData, EdgeData, Screen } from '../pathExploration';

export default function useFigmaFlowStats({
  testerResponses,
  goalScreens,
  startNodeId,
}: FigmaPathExplorationProps) {
  return useMemo(() => {
    const nodeMap = new Map<string, NodeData>();
    const nodeVisitCounts = new Map<string, number>();
    const edgeMap = new Map<string, EdgeData>();
    const columnMap = new Map<number, Set<string>>();
    const totalVisits = testerResponses.length;

    // First Pass: Process Nodes
    testerResponses.forEach((response: IReportStateByTester) => {
      response.screens.forEach((screen: Screen, index) => {
        const column = index;
        const nodeKeyWithColumn = `${screen.nodeKey}-${column}`;

        nodeVisitCounts.set(
          nodeKeyWithColumn,
          (nodeVisitCounts.get(nodeKeyWithColumn) || 0) + 1
        );

        if (!nodeMap.has(nodeKeyWithColumn)) {
          createNewNode(nodeKeyWithColumn, screen, column);
        } else {
          updateExistingNode(nodeKeyWithColumn);
        }
      });

      handleGivenUp(response);
    });

    // Second Pass: Process Edges
    testerResponses.forEach((response: IReportStateByTester) => {
      response.screens.forEach((screen: Screen, index) => {
        const currentNodeKey = `${screen.nodeKey}-${index}`;
        if (index > 0) {
          const previousNodeKey = `${response.screens[index - 1].nodeKey}-${
            index - 1
          }`;
          processEdge(currentNodeKey, previousNodeKey);
        }
      });
    });

    return {
      nodes: Array.from(nodeMap.values()),
      edges: Array.from(edgeMap.values()),
    };

    // Helper Functions
    function createNewNode(nodeKeyWithColumn: string, screen: Screen, column: number) {
      const rowSet = columnMap.get(column) || new Set();
      rowSet.add(nodeKeyWithColumn);
      columnMap.set(column, rowSet);

      nodeMap.set(nodeKeyWithColumn, {
        name: screen.name,
        image: screen.image,
        id: nodeKeyWithColumn,
        column,
        row: rowSet.size - 1,
        screen,
        count: 1,
        totalVisits,
        givenUp: 0,
        step: column + 1,
        isGoal: Array.isArray(goalScreens)
          ? goalScreens.includes(screen.nodeKey)
          : goalScreens === screen.nodeKey,
        isStart: startNodeId === screen.nodeKey && column === 0,
      });
    }

    function updateExistingNode(nodeKeyWithColumn: string) {
      const node = nodeMap.get(nodeKeyWithColumn)!;
      node.count = nodeVisitCounts.get(nodeKeyWithColumn)!;
    }

    function processEdge(currentNodeKey: string, previousNodeKey: string) {
      const edgeId = `${previousNodeKey}-${currentNodeKey}`;
      const sourceVisits = nodeVisitCounts.get(previousNodeKey) || 1;

      if (!edgeMap.has(edgeId)) {
        createNewEdge(edgeId, previousNodeKey, currentNodeKey, sourceVisits);
      } else {
        updateExistingEdge(edgeId, sourceVisits);
      }
    }

    function createNewEdge(
      edgeId: string,
      source: string,
      target: string,
      sourceVisits: number
    ) {
      edgeMap.set(edgeId, {
        id: edgeId,
        source,
        target,
        count: 1,
        total: sourceVisits,
        percentage: parseFloat((100 / sourceVisits).toFixed(2)),
      });
    }

    function updateExistingEdge(edgeId: string, sourceVisits: number) {
      const edge = edgeMap.get(edgeId)!;
      edge.count++;
      edge.total = sourceVisits;
      edge.percentage = parseFloat((edge.count / edge.total * 100).toFixed(2));
    }

    function handleGivenUp(response: IReportStateByTester) {
      if (response.givenUp && response.screens.length > 0) {
        const lastScreen = response.screens[response.screens.length - 1];
        const lastStep = response.screens.length - 1;
        const nodeKeyWithColumn = `${lastScreen.nodeKey}-${lastStep}`;
        if (nodeMap.has(nodeKeyWithColumn)) {
          nodeMap.get(nodeKeyWithColumn)!.givenUp += 1;
        }
      }
    }
  }, [testerResponses, goalScreens, startNodeId]);
}