import React, { useState } from "react";
import parse from "html-react-parser";
import _ from "lodash";
import "../../../../../tailwind.generated.css";

import { Stepper } from "./ContentBlock/Stepper";
import { Preview } from "./ContentBlock/Preview";
import Task from "./Task";
import FigmaSuccessModal from "./FigmaSuccessModal";
import FigmaTestView from "../../../../Figma/FigmaTestView";

import { Block, IFigmaBlockWithPrototypeData } from "../../../../../models/Test";
import useFigmaHandlers, { FigmaPrototypeData } from '../hooks/useFigmaHandlers';
import FigmaIFrame from '../../../../Figma/FigmaIFrame';

export interface IFigmaProps {
  data: IFigmaBlockWithPrototypeData & Block;
  testId: string;
  answerId: string;
  isPreview: boolean;
  withProgress: boolean;
  currentStep: number;
  totalSteps: number;
  isLoading: boolean;

  setIsLoading: (isLoading: boolean) => void;
  handleFigmaGiveUp: (data: FigmaPrototypeData) => void;
  handleFigmaSuccess: (data: FigmaPrototypeData) => void;
  plan: string;
}

const Figma = (props: IFigmaProps) => {
  const data = props.data;
  const [isSuccessModalOpen, setisSuccessModalOpen] = useState(false);

  // if on, then we are recording
  const withVideo = !!data.withVideo && !props.isPreview;

  const figmaHandlers = useFigmaHandlers({
    testId: props.testId,
    blockId: data.blockId,
    answerId: props.answerId,
    plan: props.plan,
    withVideo,
    data: props.data,
    handleFigmaGiveUp: props.handleFigmaGiveUp,
    onGoalNodeReached: onFigmaGoalNodeReached,
  })

  function onFigmaGoalNodeReached() {
    // wait for 1000ms to show success modal
    setTimeout(() => {
      setisSuccessModalOpen(true);
    }, 1000);
  }

  function onSuccessModalClick() {
    setisSuccessModalOpen(false);
    props.handleFigmaSuccess(figmaHandlers.prototypeState as FigmaPrototypeData)
  }

  return (
    <div className="w-full h-full flex flex-col relative" id="testContentBlock">
      <div className="h-full relative touch-none">
        <Task
          description={data.description ? parse(data.description) : undefined}
          text={parse(data.questionHtml || data.text)}
          onStart={figmaHandlers.onTaskStart}
          onGiveUp={figmaHandlers.onTaskGiveUp}
          isLoading={props.isLoading}
          blockId={data.blockId}
        />

        <FigmaSuccessModal
          onClick={onSuccessModalClick}
          isOpen={isSuccessModalOpen}
          isLoading={figmaHandlers.recording.isUploading}
        />
        {data.prototypeLink && (
          <FigmaIFrame
            options={{
              fileId: data.fileId || '',
              startNodeId: data.startNodeId || '',
              fileVersion: data.fileVersion || '',
              scaling: data.scaling || "scale-down-width",
              fps: 10,
              video: withVideo === true,
            }}
            width={'100%'}
            height={'100%'}
            onLoad={() => props.setIsLoading(false)}
            onClick={(e) => {
              console.log('click', e);
              figmaHandlers.onFigmaClick(e);
            }}
            onPresentedNodeChanged={(e) => {
              console.log('presented node changed', e);
              figmaHandlers.onFigmaTransition(e);
            }}
            onSizeRetrieved={(e) => figmaHandlers.onFigmaSizeRetrieved(e)}
          />
        )}

        {/* Prototype in html format that imported with code */}
        {data.prototypeData && (
          <FigmaTestView
            className="w-full h-full bg-black"
            startNodeId={data.prototypeData.startNodeId}
            prototype={data.prototypeData}
            showDefaultCursor={!!data.showDefaultCursor}
            onLoad={() => props.setIsLoading(false)}
            onClick={figmaHandlers.onFigmaClick}
          />
        )}
      </div>
    </div>
  );

};

export default Figma;
