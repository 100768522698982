import React, { useState, useRef } from "react";
import clsx from "clsx";
import "../tailwind.generated.css";

export type DropdownOption = {
  name: string | JSX.Element;
  value: string;
  disabled?: boolean;
};

export type Divider = "divider";

type DropdownProps = {
  type: "single" | "none";
  handler: (value: string) => void;
  items: Array<DropdownOption | Divider>;
  header?: string;
  children?: React.ReactNode;
  value?: string;
  light?: boolean;
  parentTestId?: string;
};

const Dropdown = ({ type, items, header, handler, children, value, light, parentTestId }: DropdownProps) => {
  // const positionStyles = {
  //   left: {},
  //   center: { left: "50%", transform: "translate(-50%, 0%)" },
  //   right: { right: "100%", transform: "translate(50%, 0%)" },
  // };

  const [isOpen, setIsOpen] = useState(false);
  const [_, setSelectedOptions] = useState(value);

  const dropdownRef = useRef(null);

  const isEmpty = items.length === 0;

  const handleSelect = (itemValue: string) => {

    console.log("handleSelectitemValue", itemValue);

    if (type === "none") {
      handler(itemValue);
      setIsOpen(false);
    }

    if (type === "single") {
      handler(itemValue);
      setSelectedOptions(itemValue);
      setIsOpen(false);

      console.log("selectedOptions", _);
      console.log("itemValue", itemValue);

    }
  };

  return (
    <div className="dropdownController relative" tabIndex={-1} onBlur={() => setIsOpen(false)}>
      <div
        ref={dropdownRef}
        onClick={(e) => {
          if (!isEmpty) {
            e.preventDefault();
            e.stopPropagation();
            setIsOpen(true);
          }
        }}
      >
        {children}
      </div>
      <div className={`z-999 absolute ${isOpen ? "block" : "hidden"} min-w-full`}>
        <div className={clsx("rounded-lg p-2 mt-1 bg-white shadow-xl border-1 border-gray-400 text-gray-800")}>
          {header && header}
          {items.map((item, i, items) => {
            if (item === "divider") {
              // Check if it's the first or last item or if both previous and next items are dividers
              if (i === 0 || i === items.length - 1 || items[i - 1] === "divider" || items[i + 1] === "divider") {
                return null; // Do not render the divider
              }
              return <div key={`divider-${i}`} className="w-full bg-gray-200 my-2 h-px" />;
            }
            return (
              <div
                key={item.value}
                data-value={item.value}
                data-testid={parentTestId && `${parentTestId}.item`}
                className={`flex items-center cursor-pointer rounded-md py-1 px-2 w-full text-gray-800 shadow-none hover:bg-gray-200 transition-all duration-75 ease-in`}
                onClick={(e) => {
                  if (!item.disabled) {
                    e.preventDefault();
                    e.stopPropagation();
                    handleSelect(item.value);
                  }
                }}
              >
                <div
                  className={`dropdown__item cursor-pointer rounded-md w-full whitespace-nowrap overflow-hidden text-ellipsis max-w-[300px] ${
                    item.disabled ? "opacity-50 cursor-not-allowed" : "cursor-pointer"
                  }`}
                  title={typeof item.name === 'string' ? item.name : ''}
                >
                  {item.name}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Dropdown;
