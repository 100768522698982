import { BlockType } from './BlockType';

export const BLOCKS_WITH_IMAGES = [BlockType.openquestion, BlockType.context, BlockType.choice, BlockType.fiveseconds];
export const BLOCKS_OPTIONAL = [BlockType.openquestion, BlockType.preference, BlockType.choice, BlockType.scale, BlockType.matrix];
export const BLOCKS_SUPPORTED_BY_CONDITIONS = [BlockType.openquestion, BlockType.preference, BlockType.choice, BlockType.scale, BlockType.figma];

export function canHaveImage(type: BlockType) {
	return BLOCKS_WITH_IMAGES.includes(type);
}

export function canBeOptional(type: BlockType) {
	return BLOCKS_OPTIONAL.includes(type);
}