import React from 'react';
import { TaggableChatMessage } from '../Chat/TaggableChatMessage';
import { ReportChatMessage } from '../Models/ReportChatMessage';
import clsx from 'clsx';
import { getFormattedDate } from '../../utils';
import { IconCheck } from '../../../../icons';

import { useTranslation } from 'react-i18next';

export function AIChatMessages(props: { messages: ReportChatMessage[]; className?: string; showDate?: boolean; showScrollPadding: boolean; initialQuestion?: string; }) {

	const { t } = useTranslation();

	const firstMessageDate = props.messages[0]?.createdAt;
	const formattedDate = getFormattedDate(firstMessageDate);

	const renderMessagePair = (aiMessage: ReportChatMessage, userMessage?: ReportChatMessage) => (
		<div className="ai-chat-messages__pair grid grid-cols-[minmax(240px,30%)_auto] gap-3">
			<TaggableChatMessage
				i={aiMessage.id}
				key={aiMessage.id}
				message={aiMessage}
				showDate={props.showDate}
			/>
			{userMessage ? (
				<TaggableChatMessage
					i={userMessage.id}
					key={userMessage.id}
					message={userMessage}
					showDate={props.showDate}
				/>
			) : (
				<div className="no-reply text-gray-500">{t('No reply')}</div>
			)}
		</div>
	);

	return (
		<div className='ai-chat-messages-pair__container'>
			{formattedDate && (
				<div className="ai-chat-messages-pair__date text-gray-600 mb-2">
					{formattedDate}
				</div>
			)}
			<div className={clsx("ai-chat-messages flex flex-col border-1 border-gray-200 rounded-xl p-3 shadow-xxxs ", props.className)}>
				<div className='ai-chat-messages__content flex flex-col gap-4'>
					{props.messages.map((message, index) => {
						if (index % 2 === 0) {
							const userMessage = props.messages[index + 1];
							return renderMessagePair(message, userMessage);
						}
						return null;
					})}
				</div>
			</div>
		</div>
	);
}